// Header.tsx
import React from 'react';
import UserAvatar from './UserAvatar';

const Header: React.FC = () => {
  return (
    <header className="bg-gray-200 flex justify-between items-center px-4 py-2">
      <h1></h1>
      <UserAvatar />
    </header>
  );
};

export default Header;
