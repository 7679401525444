import React from 'react';
import logo from '../assets/kanban-agent-transparent.png';

const Sidebar: React.FC = () => {
  return (
    <aside className="w-64 bg-gray-800 text-white">
      <div className="p-4">
        <div style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          margin: '0 10px auto',
        }}>
          <img src={logo} alt="Kanban Agent" className="w-32 mx-auto" />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
