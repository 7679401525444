import axios from 'axios';
import store from '../redux/store';

const baseURL = 'https://api.kanbanagent.com';

const apiService = axios.create({
  baseURL,
});

apiService.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
    //   store.dispatch(clearToken());
      // Other logout logic
    }
    return Promise.reject(error);
  }
);

export default apiService;
