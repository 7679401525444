import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import TasksBoard from './components/TasksBoard';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { Auth, getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from './config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from './core/redux/authSlice';
import { RootState } from './core/redux/store';
import useAuth from './core/hooks/useAuth';

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();
  
  return isLoggedIn ? 
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col w-full">
        <Header />
        <TasksBoard />
      </div>
    </div> : <Login />;
};

export default App;
