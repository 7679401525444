// UserInfoPopover.tsx
import React from 'react';
import useAuth from '../core/hooks/useAuth';

const UserInfoPopover: React.FC = () => {
  const { photo, name } = useAuth();
  return (
    <div className="absolute bg-white p-4 border rounded shadow">
      {name}
    </div>
  );
};

export default UserInfoPopover;
