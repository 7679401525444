import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';
import { CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../core/redux/store';
import { createTask, fetchTasks, updateTaskAPI } from '../core/api/taskApi';
import { Status } from '../core/model/status.model';

interface Task {
  id: string;
  content: string;
}

interface Column {
  id: string;
  title: string;
  taskIds: string[];
}

interface InitialData {
  tasks: { [key: string]: Task };
  columns: { [key: string]: Column };
  columnOrder: string[];
}

const priorities = ['Low', 'Medium', 'High'];

const initialData: InitialData = {
  tasks: {
    'task-1': { id: 'task-1', content: 'Take out the garbage' },
    'task-2': { id: 'task-2', content: 'Watch my favorite show' },
    'task-3': { id: 'task-3', content: 'Charge my phone' },
    'task-4': { id: 'task-4', content: 'Cook dinner' },
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'To do',
      taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
    },
    'column-2': {
      id: 'column-2',
      title: 'In progress',
      taskIds: [],
    },
    'column-3': {
      id: 'column-3',
      title: 'Done',
      taskIds: [],
    },
  },
  columnOrder: ['column-1', 'column-2', 'column-3'],
};
const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  userSelect: isDragging ? 'none' : undefined,
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '8px',
  border: '1px solid #ccc',
  background: isDragging ? "lightgreen" : "white",
  ...draggableStyle
} as CSSProperties);

const TasksBoard: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const [statusList, setStatusList] = useState<Status[]>([]);
  const [selectedStatusId, setSelectedStatusId] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  
  const getStatusTasks = () => {
    fetchTasks().then((data) => {
      const statusReordered: Status[] = []
      const datamapped = data.map( d => {
        return {
          ...d,
          tasks: d.tasks.map(t => t[0])
        } as Status;
      });
      // TODO: Adjust the api to have an order field for the statuses
      statusReordered.push(datamapped.find(s => s.name === 'To Do') as Status);
      statusReordered.push(datamapped.find(s => s.name === 'In Progress') as Status);
      statusReordered.push(datamapped.find(s => s.name === 'Done') as Status);
      setStatusList(statusReordered);
      setSaving(false);
    });
  }

  useEffect(() => {
    getStatusTasks();
  }, [dispatch]);
  
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    priority: '',
  });
  const [errors, setErrors] = useState<{ title?: string, description?: string, priority?: string }>({});
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setFormData({ title: '', description: '', priority: '' });
    setErrors({});
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const newErrors: { [key: string]: string } = {};
    if (!formData.title) {
      newErrors.title = 'Title is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setSaving(true);
      createTask({
        ...formData,
        statusId: selectedStatusId,
      }).then(data => {
        getStatusTasks();
      });
      closeModal();
    }
  };

  const onDragEnd = (result: DropResult) => {
    setSaving(true);
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = statusList.find(s => s._id === source.droppableId);
    const finish =statusList.find(s => s._id === destination.droppableId);

    if (start && finish) {
      var task = start.tasks[source.index];
      if (start === finish) {
        const newTaskIds = Array.from(start?.tasks || []);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, start.tasks[source.index]);
  
        const newStatus = {
          ...start,
          taskIds: newTaskIds,
        };

        updateTaskAPI(task._id, destination.droppableId).then(() => {
          getStatusTasks();
        })
  
        // setStatusList((prevData) => ({
        //   ...prevData,
        //   columns: {
        //     ...prevData,
        //     [newStatus._id]: newStatus,
        //   },
        // }));
        return;
      }
  
      const startTaskIds = Array.from(start.tasks);
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        tasks: startTaskIds,
      };
  
      const finishTaskIds = Array.from(finish.tasks);
      finishTaskIds.splice(destination.index, 0, task);
      const newFinish = {
        ...finish,
        tasks: finishTaskIds,
      };

      console.log('newStart:', newStart);
      console.log('newFinish:', newFinish);

      const currentStatus = statusList.filter(s => s._id !== source.droppableId && s._id !== destination.droppableId);
      currentStatus.push(newStart);
      currentStatus.push(newFinish);

      // setStatusList((prevData) => ({
      //   ...prevData,
      //   columns: {
      //     ...prevData,
      //     [newStart._id]: newStart,
      //     [newFinish._id]: newFinish,
      //   },
      // }));
  
      // setStatusList(currentStatus);
      updateTaskAPI(task._id, destination.droppableId).then(() => {
        setSaving(true);
        getStatusTasks();
      })
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex space-x-4 p-4">
        {statusList && statusList.length > 0 && statusList.map((status) => {
          const tasks = status.tasks;

          return (
            <div key={status._id} className="w-1/3 bg-gray-100 p-2 rounded-lg shadow-md">
              <h2 className="text-xl font-bold mb-2">{status.name}</h2>
              <Droppable key={status._id} droppableId={status._id}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="p-2 bg-white rounded-md"
                  >
                    {tasks.map((task, index) => (
                      <Draggable
                      key={task._id}
                      draggableId={task._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around"
                            }}
                          >
                            {task.title}
                          </div>
                        </div>
                      )}
                    </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <br />
              <button 
              disabled={saving}
              onClick={() => {
                setSelectedStatusId(status._id);
                openModal();
              }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                {saving ? 'Saving...' : 'Add Task'}
              </button>
            </div>
          );
        })}
      </div>
      {isOpen && (
        <div className="fixed inset-0 z-10 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-900 bg-opacity-75" onClick={closeModal}></div>

          <div className="bg-white p-4 rounded z-20">
            <h2 className="text-lg font-bold mb-2">New Task</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={(e) => handleChange(e)}
                placeholder="Title"
                className="mb-2 p-2 border rounded"
              />
              {errors.title && <p className="text-red-500">{errors.title}</p>}

              <div className="mt-4">
                <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">
                  Save
                </button>
                <button onClick={closeModal} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </DragDropContext>
  );
};

export default TasksBoard;
