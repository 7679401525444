// UserAvatar.tsx
import React from 'react';
import LazyLoad from 'react-lazyload';
import UserInfoPopover from './UserInfoPopover';
import useAuth from '../core/hooks/useAuth';

const UserAvatar: React.FC = () => {
  const { photo, name } = useAuth();
  const [showPopover, setShowPopover] = React.useState(false);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  return (
    <div>
      <LazyLoad once>
        <img src={photo} alt={name} onClick={togglePopover} />
      </LazyLoad>
      {showPopover && <UserInfoPopover />}
    </div>
  );
};

export default UserAvatar;
