// userSlice.ts
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const userFromSession = sessionStorage.getItem('user');
const initialUserState = userFromSession ? JSON.parse(userFromSession) : {
  isLoggedIn: false,
  email: '',
  name: '',
  photo: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    login(state, action) {
      const { email, name, photo, token } = action.payload;
      state.isLoggedIn = true;
      state.email = email;
      state.name = name;
      state.photo = photo;
      state.token = token;
      sessionStorage.setItem('user', JSON.stringify(state));
    },
    logout(state) {
      state.isLoggedIn = false;
      state.email = '';
      state.name = '';
      state.photo = '';
      sessionStorage.removeItem('user');
    },
    setToken(state, action: PayloadAction<string | null>) {
      state.token = action.payload;
    },
    clearToken(state) {
      state.token = null;
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
