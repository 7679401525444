import { ApiStatusResponseDto } from '../dto/api-status-response.dto';
import { TaskDto } from '../dto/task.dto';
import apiService from './apiService';

const taskURL = '/status';

export const fetchTasks = async (): Promise<ApiStatusResponseDto[]> => {
  const response = await apiService.get<ApiStatusResponseDto[]>(taskURL);
  return response.data;
};

export const createTask = async (newTask: any): Promise<TaskDto> => {
  const response = await apiService.post<TaskDto>('tasks', newTask);
  return response.data;
};

export const updateTaskAPI = async (taskId: string, statusId: string): Promise<any> => {
  const response = await apiService.put<any>(`tasks/${taskId}/status`, {
    statusId
  });
  return response.data;
};

export const deleteTask = async (taskId: number): Promise<void> => {
  await apiService.delete(`${taskURL}/${taskId}`);
};
