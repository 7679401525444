// useAuth.ts
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Auth, getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from '../../config/firebase';

import { RootState } from '../redux/store';
import { login, logout } from '../redux/authSlice';


export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

const useAuth = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, email, name, photo } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const userFromSession = sessionStorage.getItem('user');
    if (userFromSession) {
      const userData = JSON.parse(userFromSession);
      dispatch(login(userData));
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        console.log('User:', token);
        dispatch(login({
          email: user.email || '',
          name: user.displayName || '',
          photo: user.photoURL || '',
          token: token,
        }));
      } else {
        dispatch(logout());
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  return { isLoggedIn, email, name, photo };
};

export default useAuth;
